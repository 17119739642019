import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from './Button';
import {
    selectAddWiring,
    selectConfigurationColor,
    selectConfigurationDiffusor,
    selectConfigurationPower,
    selectConfigurationProtection,
    selectForm,
    selectUseEmergency,
    setAddWiring,
    setConfigurationColor,
    setConfigurationPendelLength,
    setConfigurationPower,
    setConfigurationProtection,
    setEmergency,
} from '../features/configuration/configurationSlice';
import {
    selectColors,
    selectPowers,
    selectProtections,
    selectRulesetName,
    selectUserSelectedInstallation,
    selectUserSelectedPendelLength,
    selectWirings,
    setUserSelectedColor,
    setUserSelectedEmergency,
    setUserSelectedPendelLength,
    setUserSelectedPower,
    setUserSelectedProtection,
    setUserWirings,
} from '../features/products/productsSlice';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { renderPowerDetailText } from '../services/service';
import Translator, { imperativeTranslateMessage } from './Translator';

export default function SidebarConfiguration() {
    const dispatch = useDispatch();

    const [colorClicked, setColorClicked] = useState(null);
    const [protectionClicked, setProtectionClicked] = useState(null);
    const [powerClicked, setPowerClicked] = useState(null);
    const [wiringClicked, setWiringClicked] = useState(null);
    const [emergencyClicked, setEmergencyClicked] = useState(null);
    const [pendelClicked, setPendelClicked] = useState(null);

    //from configuration
    const color = useSelector(selectConfigurationColor) || { kelvin: 4000, name: 'Neutralweiss' };
    const protection = useSelector(selectConfigurationProtection) || 'IP20';
    const power = useSelector(selectConfigurationPower) || { name: 'HPE', lumen: null, watt: null };
    const diffusor = useSelector(selectConfigurationDiffusor) || 'DEFAULT';
    const form = useSelector(selectForm);
    const pendelLength = useSelector(selectUserSelectedPendelLength) || 1500;
    const installation = useSelector(selectUserSelectedInstallation);
    const wiring = useSelector(selectAddWiring) || false;
    const isEmergency = useSelector(selectUseEmergency) || false;

    //from ruleset
    const colors = JSON.parse(useSelector(selectColors));
    const protections = useSelector(selectProtections);
    const wirings = useSelector(selectWirings);
    const powers = JSON.parse(useSelector(selectPowers));
    const rulesetName = useSelector(selectRulesetName);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    resetButtons();
                }
            }
            // Bind the event listener
            if (colorClicked || protectionClicked || powerClicked || pendelClicked || wiringClicked || emergencyClicked) {
                document.addEventListener('mousedown', handleClickOutside);
            }
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref, colorClicked, protectionClicked, powerClicked, pendelClicked, wiringClicked, emergencyClicked]);
    }
    let powerItem = { name: '', diffusor: '' };
    console.log(wirings);

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <div className={'w-80 h-full py-6 inline-block z-20 ' + (showSubmenu() ? '' : 'shadow-custom')} ref={wrapperRef}>
            <div className="text-xl font-medium px-6">
                <Translator id="canvas.left.configurator.title" />
            </div>

            <div className={'mt-6 w-full text-left  ' + (colorClicked ? 'bg-gradient-to-r from-white to-configuration-grey' : '')}>
                <div
                    className="px-6 py-2 cursor-pointer"
                    onClick={() => {
                        resetButtons();
                        if (!colorClicked) {
                            setColorClicked(true);
                        }
                    }}
                >
                    <p className="relative">
                        <FontAwesomeIcon icon={['far', 'lightbulb-on']} />
                        <span className="pl-2 font-book">
                            <Translator id="canvas.left.configurator.color.title" />
                        </span>
                        <span className="absolute right-0 w-6 h-6 bg-configuration-grey rounded-full text-center">
                            <FontAwesomeIcon icon={['far', colorClicked ? 'times' : 'edit']} className="!text-14 pt-1 pl-[1px]" />
                        </span>
                    </p>
                    <p className="pl-7">
                        {color.kelvin === 'TW' ? '2700 K - 6500 K ' : color.kelvin + ' K '}-{' '}
                        {color.name === 'Neutralweiss' ? (
                            <Translator id="canvas.left.configurator.color.neutral" />
                        ) : color.name === 'Warmweiss' ? (
                            <Translator id="canvas.left.configurator.color.warm" />
                        ) : color.name === 'Tunable White' ? (
                            <Translator id="canvas.left.configurator.color.tunable" />
                        ) : null}
                    </p>
                </div>
                <div
                    className={
                        'absolute top-0 bottom-0 w-72 h-full bg-white left-80 py-6 shadow-custom transition-all duration-300 ' +
                        (colorClicked ? 'left-80 pointer-events-auto opacity-100' : 'left-40 pointer-events-none opacity-0')
                    }
                >
                    <p className="text-xl font-book px-6 mb-6">
                        <Translator id="canvas.left.configurator.color.title" />
                    </p>
                    {colors.map((colorItem) => (
                        <Button
                            key={colorItem.kelvin}
                            className={
                                'text-left block mb-4 w-full py-2 px-6 ' +
                                (colorItem.kelvin === color.kelvin ? 'bg-gradient-to-r from-white to-configuration-grey' : '') +
                                (diffusor === 'boost' && colorItem.kelvin === 'TW' ? ' opacity-40 cursor-not-allowed' : '')
                            }
                            onClick={() => {
                                if (!(diffusor === 'boost' && colorItem.kelvin === 'TW')) {
                                    if (colorItem.kelvin === 'TW' && (power.name === 'xHE' || power.name === 'HE')) {
                                        dispatch(setConfigurationPower({ name: 'HO', lumen: null, watt: null }));
                                        dispatch(setUserSelectedPower({ name: 'HO', lumen: null, watt: null }));
                                    }
                                    dispatch(setConfigurationColor(colorItem));
                                    dispatch(setUserSelectedColor(colorItem));
                                    resetButtons();
                                }
                            }}
                        >
                            <div
                                className={
                                    'inline-block mr-3.5 w-10 h-10 ' +
                                    (colorItem.kelvin === 4000
                                        ? 'bg-4000k'
                                        : colorItem.kelvin === 3000
                                        ? 'bg-3000k'
                                        : 'bg-gradient-to-r from-tw-start to-tw-end')
                                }
                            />
                            <div className="inline-block align-top">
                                <span className="font-book">{colorItem.kelvin === 'TW' ? '2700 K - 6500 K' : colorItem.kelvin + ' K'}</span>
                                <span className="block !text-14">
                                    {colorItem.name === 'Neutralweiss' ? (
                                        <Translator id="canvas.left.configurator.color.neutral" />
                                    ) : colorItem.name === 'Warmweiss' ? (
                                        <Translator id="canvas.left.configurator.color.warm" />
                                    ) : colorItem.name === 'Tunable White' ? (
                                        <Translator id="canvas.left.configurator.color.tunable" />
                                    ) : null}
                                </span>
                            </div>
                        </Button>
                    ))}
                </div>
            </div>

            <div className={'mt-4 w-full text-left ' + (protectionClicked ? 'bg-gradient-to-r from-white to-configuration-grey' : '')}>
                <div
                    className="px-6 py-2 cursor-pointer"
                    onClick={() => {
                        resetButtons();
                        if (!protectionClicked) {
                            setProtectionClicked(true);
                        }
                    }}
                >
                    <p className="relative">
                        <FontAwesomeIcon icon={['fas', 'shield-halved']} />
                        <span className="pl-2 font-book">
                            <Translator id="canvas.left.protection.title" />
                        </span>
                        <span className="absolute right-0 w-6 h-6 bg-configuration-grey rounded-full text-center">
                            <FontAwesomeIcon icon={['far', protectionClicked ? 'times' : 'edit']} className="!text-14 pt-1 pl-[1px]" />
                        </span>
                    </p>
                    <p className="pl-7">{protection}</p>
                </div>
                <div
                    className={
                        'absolute top-0 bottom-0 w-72 h-full bg-white left-80 py-6 shadow-custom transition-all duration-300 ' +
                        (protectionClicked ? 'left-80 pointer-events-auto opacity-100' : 'left-40 pointer-events-none opacity-0')
                    }
                >
                    <p className="text-xl font-book px-6 mb-6">
                        <Translator id="canvas.left.protection.title" />
                    </p>
                    {Object.entries(protections).map(([key, value]) => {
                        return (
                            <Button
                                key={key}
                                className={
                                    'text-left block mb-4 w-full py-3 px-6 ' +
                                    (protection === value ? 'bg-gradient-to-r from-white to-configuration-grey' : '') +
                                    (form !== 'I' && value === 'IP54' ? ' opacity-40 cursor-not-allowed' : '') +
                                    (diffusor !== 'run' && rulesetName !== 'Flow' && value === 'IP40' ? ' opacity-40 cursor-not-allowed' : '') +
                                    (installation === 'pendulum' && rulesetName === 'Flow' && value === 'IP44' ? ' opacity-40 cursor-not-allowed' : '') +
                                    (installation !== 'pendulum' && rulesetName === 'Flow' && value === 'IP40' ? ' opacity-40 cursor-not-allowed' : '')
                                }
                                onClick={() => {
                                    if (
                                        (form !== 'I' && value === 'IP54') ||
                                        (diffusor !== 'run' && rulesetName !== 'Flow' && value === 'IP40') ||
                                        (installation === 'pendulum' && rulesetName === 'Flow' && value === 'IP44') ||
                                        (installation !== 'pendulum' && rulesetName === 'Flow' && value === 'IP40')
                                    ) {
                                        //do nothing
                                    } else {
                                        dispatch(setConfigurationProtection(value));
                                        dispatch(setUserSelectedProtection(value));
                                        resetButtons();
                                    }
                                }}
                            >
                                <div className="inline-block mr-3.5 w-10 h-10 bg-configuration-grey text-center">
                                    <FontAwesomeIcon icon={['fas', 'shield-halved']} className="pt-[11px]" />
                                </div>
                                <div className="inline-block align-baseline">
                                    <span className="font-book">{value}</span>
                                </div>
                            </Button>
                        );
                    })}
                </div>
            </div>

            <div className={'mt-4 w-full text-left ' + (powerClicked ? 'bg-gradient-to-r from-white to-configuration-grey' : '')}>
                <div
                    className="px-6 py-2 cursor-pointer"
                    onClick={() => {
                        resetButtons();
                        if (!powerClicked) {
                            setPowerClicked(true);
                        }
                    }}
                >
                    <p className="relative">
                        <FontAwesomeIcon icon={['far', 'battery-bolt']} />
                        <span className="pl-2 font-book">
                            <Translator id="canvas.left.power.type.title" />
                        </span>
                        <span className="absolute right-0 w-6 h-6 bg-configuration-grey rounded-full text-center">
                            <FontAwesomeIcon icon={['far', powerClicked ? 'times' : 'edit']} className="!text-14 pt-1 pl-[1px]" />
                        </span>
                    </p>
                    <p className="pl-7">
                        <Translator id={'canvas.left.power.type.' + power.name.toLowerCase() + '.title'} /> (
                        <Translator id={renderPowerDetailText(power.name)} />)
                    </p>
                </div>
                <div
                    className={
                        'absolute top-0 bottom-0 w-72 h-full bg-white py-6 shadow-custom transition-all duration-300 ' +
                        (powerClicked ? 'left-80 pointer-events-auto opacity-100' : 'left-40 pointer-events-none opacity-0')
                    }
                >
                    <p className="text-xl font-book px-6 mb-6">
                        <Translator id="canvas.left.power.type.title" />
                    </p>
                    {powers.map((powerItem) => (
                        <Button
                            key={powerItem.name + ' ' + powerItem.lumen}
                            className={
                                'text-left block mb-4 w-full pt-2.5 pb-[9px] px-6 ' +
                                (powerItem.name === power.name ? 'bg-gradient-to-r from-white to-configuration-grey' : '') +
                                (powerItem.diffusor.toLowerCase().includes(diffusor) ? '' : ' opacity-40 cursor-not-allowed') +
                                (color.kelvin === 'TW' && (powerItem.name === 'HE' || powerItem.name === 'xHE') ? ' opacity-40 cursor-not-allowed' : '')
                            }
                            onClick={() => {
                                if (powerItem.diffusor.toLowerCase().includes(diffusor)) {
                                    dispatch(setConfigurationPower(powerItem));
                                    dispatch(setUserSelectedPower(powerItem));
                                    resetButtons();
                                }
                            }}
                        >
                            <div className="inline-block mr-3.5 w-10 h-10 bg-configuration-grey text-center">
                                <FontAwesomeIcon icon={['far', 'battery-bolt']} className="pt-[11px]" />
                            </div>
                            <div className="inline-block align-top">
                                <span className="font-book">
                                    <Translator id={'canvas.left.power.type.' + powerItem.name.toLowerCase() + '.title'} />
                                </span>
                                {/*<span className="block !text-14">({powerItem.lumen} lm, {powerItem.watt} W / 1,2m) - {powerItem.diffusor ? powerItem.diffusor : powerItem.product}</span>*/}
                                <span className="block !text-14">
                                    <Translator id={renderPowerDetailText(powerItem.name)} />
                                </span>
                            </div>
                        </Button>
                    ))}
                </div>
            </div>

            {installation === 'pendulum' && (
                <div className={'mt-4 w-full text-left ' + (pendelClicked ? 'bg-gradient-to-r from-white to-configuration-grey' : '')}>
                    <div
                        className="px-6 py-2 cursor-pointer"
                        onClick={() => {
                            resetButtons();
                            if (!pendelClicked) {
                                setPendelClicked(true);
                            }
                        }}
                    >
                        <p className="relative">
                            <FontAwesomeIcon icon={['far', 'light-ceiling']} />
                            <span className="pl-2 font-book">
                                <Translator id="canvas.left.pendulum.title" />
                            </span>
                            <span className="absolute right-0 w-6 h-6 bg-configuration-grey rounded-full text-center">
                                <FontAwesomeIcon icon={['far', pendelClicked ? 'times' : 'edit']} className="!text-14 pt-1 pl-[1px]" />
                            </span>
                        </p>
                        <p className="pl-7">{pendelLength} mm</p>
                    </div>
                    <div
                        className={
                            'absolute top-0 bottom-0 w-72 h-full bg-white py-6 shadow-custom transition-all duration-300 ' +
                            (pendelClicked ? 'left-80 pointer-events-auto opacity-100' : 'left-40 pointer-events-none opacity-0')
                        }
                    >
                        <p className="text-xl font-book px-6 mb-6">
                            <Translator id="canvas.left.pendulum.title" />
                        </p>
                        <Button
                            className={
                                'text-left block mb-4 w-full pt-2.5 pb-[9px] px-6 ' +
                                (pendelLength === 1500 ? 'bg-gradient-to-r from-white to-configuration-grey' : '')
                            }
                            onClick={() => {
                                dispatch(setUserSelectedPendelLength(1500));
                                dispatch(setConfigurationPendelLength(1500));
                                resetButtons();
                            }}
                        >
                            <div className="inline-block mr-3.5 w-10 h-10 bg-configuration-grey text-center">
                                <FontAwesomeIcon icon={['far', 'light-ceiling']} className="pt-[11px]" />
                            </div>
                            <div className="inline-block align-baseline">
                                <span className="font-book">1500 mm</span>
                            </div>
                        </Button>
                        <Button
                            className={
                                'text-left block mb-4 w-full pt-2.5 pb-[9px] px-6 ' +
                                (pendelLength === 3000 ? 'bg-gradient-to-r from-white to-configuration-grey' : '')
                            }
                            onClick={() => {
                                dispatch(setUserSelectedPendelLength(3000));
                                dispatch(setConfigurationPendelLength(3000));
                                resetButtons();
                            }}
                        >
                            <div className="inline-block mr-3.5 w-10 h-10 bg-configuration-grey text-center">
                                <FontAwesomeIcon icon={['far', 'light-ceiling']} className="pt-[11px]" />
                            </div>
                            <div className="inline-block align-baseline">
                                <span className="font-book">3000 mm</span>
                            </div>
                        </Button>
                    </div>
                </div>
            )}
            {/* Emergency */}
            {rulesetName === 'Flow' ? (
                <div className={'mt-4 w-full text-left ' + (emergencyClicked ? 'bg-gradient-to-r from-white to-configuration-grey' : '')}>
                    <div
                        className="px-6 py-2 cursor-pointer"
                        onClick={() => {
                            resetButtons();
                            if (!emergencyClicked) {
                                setEmergencyClicked(true);
                            }
                        }}
                    >
                        <p className="relative">
                            <FontAwesomeIcon icon={['far', 'light-emergency-on']} />
                            <span className="pl-2 font-book">
                                <Translator id="canvas.left.emergency.title" />
                            </span>
                            <span className="absolute right-0 w-6 h-6 bg-configuration-grey rounded-full text-center">
                                <FontAwesomeIcon icon={['far', emergencyClicked ? 'times' : 'edit']} className="!text-14 pt-1 pl-[1px]" />
                            </span>
                        </p>
                        <p className="pl-7">
                            {isEmergency === false ? <Translator id="canvas.left.emergency.type.remove" /> : <Translator id="canvas.left.emergency.type.add" />}
                        </p>
                    </div>
                    <div
                        className={
                            'absolute top-0 bottom-0 w-72 h-full bg-white left-80 py-6 shadow-custom transition-all duration-300 ' +
                            (emergencyClicked ? 'left-80 pointer-events-auto opacity-100' : 'left-40 pointer-events-none opacity-0')
                        }
                    >
                        <p className="text-xl font-book px-6 mb-6">
                            <Translator id="canvas.left.emergency.title" />
                        </p>
                        {Object.entries({ true: true, false: false }).map(([key, value]) => {
                            return (
                                <Button
                                    key={key}
                                    className={
                                        'text-left block mb-4 w-full py-3 px-6 ' +
                                        (value === isEmergency ? 'bg-gradient-to-r from-white to-configuration-grey' : '')
                                    }
                                    onClick={() => {
                                        dispatch(setEmergency(value));
                                        dispatch(setUserSelectedEmergency(value));
                                        resetButtons();
                                    }}
                                >
                                    <div className="inline-block mr-3.5 w-10 h-10 bg-configuration-grey text-center">
                                        {value === false ? (
                                            <FontAwesomeIcon icon={['far', 'times']} className="pt-[11px]" />
                                        ) : (
                                            <FontAwesomeIcon icon={['far', 'check']} className="pt-[11px]" />
                                        )}
                                    </div>
                                    <div className="inline-block align-baseline">
                                        <span className="font-book">
                                            {value === false ? (
                                                <Translator id="canvas.left.emergency.type.remove" />
                                            ) : (
                                                <Translator id="canvas.left.emergency.type.add" />
                                            )}
                                        </span>
                                    </div>
                                </Button>
                            );
                        })}
                    </div>
                </div>
            ) : null}

            {/* Wiring */}
            {rulesetName === 'Flow' ? (
                <div className={'mt-4 w-full text-left ' + (wiringClicked ? 'bg-gradient-to-r from-white to-configuration-grey' : '')}>
                    <div
                        className="px-6 py-2 cursor-pointer"
                        onClick={() => {
                            resetButtons();
                            if (!wiringClicked) {
                                setWiringClicked(true);
                            }
                        }}
                    >
                        <p className="relative">
                            <FontAwesomeIcon icon={['far', 'diagram-nested']} />
                            <span className="pl-2 font-book">
                                <Translator id="canvas.left.wiring.title" />
                            </span>
                            <span className="absolute right-0 w-6 h-6 bg-configuration-grey rounded-full text-center">
                                <FontAwesomeIcon icon={['far', wiringClicked ? 'times' : 'edit']} className="!text-14 pt-1 pl-[1px]" />
                            </span>
                        </p>
                        <p className="pl-7">
                            {wiring === false ? <Translator id="canvas.left.wiring.type.remove" /> : <Translator id="canvas.left.wiring.type.add" />}
                        </p>
                    </div>
                    <div
                        className={
                            'absolute top-0 bottom-0 w-72 h-full bg-white left-80 py-6 shadow-custom transition-all duration-300 ' +
                            (wiringClicked ? 'left-80 pointer-events-auto opacity-100' : 'left-40 pointer-events-none opacity-0')
                        }
                    >
                        <p className="text-xl font-book px-6 mb-6">
                            <Translator id="canvas.left.wiring.title" />
                        </p>
                        {Object.entries({ true: true, false: false }).map(([key, value]) => {
                            return (
                                <Button
                                    key={key}
                                    className={
                                        'text-left block mb-4 w-full py-3 px-6 ' + (value === wiring ? 'bg-gradient-to-r from-white to-configuration-grey' : '')
                                    }
                                    onClick={() => {
                                        dispatch(setAddWiring(value));
                                        dispatch(setUserWirings(value));
                                        resetButtons();
                                    }}
                                >
                                    <div className="inline-block mr-3.5 w-10 h-10 bg-configuration-grey text-center">
                                        {value === false ? (
                                            <FontAwesomeIcon icon={['far', 'times']} className="pt-[11px]" />
                                        ) : (
                                            <FontAwesomeIcon icon={['far', 'check']} className="pt-[11px]" />
                                        )}
                                    </div>
                                    <div className="inline-block align-baseline">
                                        <span className="font-book">
                                            {value === false ? (
                                                <Translator id="canvas.left.wiring.type.remove" />
                                            ) : (
                                                <Translator id="canvas.left.wiring.type.add" />
                                            )}
                                        </span>
                                    </div>
                                </Button>
                            );
                        })}
                    </div>
                </div>
            ) : null}

            {/*
			<div className="mt-4 text-xl px-6 py-2">
				<p>
					<FontAwesomeIcon icon={['far', 'brightness-low']}/>
					<span className="pl-2 font-book">DALI</span>
				</p>
			</div>
			<div className="mt-4 text-xl px-6 py-2">
				<p>
					<FontAwesomeIcon icon={['far', 'person-running']}/>
					<span className="pl-2 font-book">Notbeleuchtung</span>
				</p>
			</div>*/}
        </div>
    );

    function showSubmenu() {
        if (powerClicked) return true;
        else if (protectionClicked) return true;
        else if (pendelClicked) return true;
        else return !!colorClicked;
    }

    function resetButtons() {
        setColorClicked(false);
        setProtectionClicked(false);
        setPowerClicked(false);
        setPendelClicked(false);
        setWiringClicked(false);
        setEmergencyClicked(false);
    }
}
