import Button from './Button';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectLineValues,
    selectOperatingDevices,
    selectOverallLumen,
    selectOverallWatt,
    selectProductPriceChf,
    selectProductPriceEur,
    selectShowSavingModal,
    setShowSavingModal,
    selectShowRequestForm,
    setShowRequestForm,
    selectShowLoadingModal,
    setSelectedVisibleLayer,
    selecSelectedVisibleLayer,
    selectIsCHF,
} from '../features/products/productsSlice';
import { selectCountryCode, setConfigurationProductPriceChf, setConfigurationProductPriceEur } from '../features/configuration/configurationSlice';
import NumberFormat from 'react-number-format';
import SavingModal from './SavingModal';
import RequestForm from './RequestForm';
import LoadingModal from './LoadingModal';
import { SWISSCODE } from '../services/service';
import Translator from './Translator';

export default function SidebarCalculatedValues({ isResultPage = false, ...props }) {
    const dispatch = useDispatch();
    const lineValues = JSON.parse(useSelector(selectLineValues));
    const productPriceEur = useSelector(selectProductPriceEur);
    const productPriceChf = useSelector(selectProductPriceChf);
    const operatingDevices = useSelector(selectOperatingDevices);
    const overallLumen = useSelector(selectOverallLumen);
    const overallWatt = useSelector(selectOverallWatt);
    const countryCode = useSelector(selectCountryCode);
    const showSavingModal = useSelector(selectShowSavingModal);
    const showRequestForm = useSelector(selectShowRequestForm);
    const showLoadingModal = useSelector(selectShowLoadingModal);
    let currentLayer = useSelector(selecSelectedVisibleLayer);

    dispatch(setConfigurationProductPriceChf(productPriceChf));
    dispatch(setConfigurationProductPriceEur(productPriceEur));

    // Country specific formatting
    let isLanguageCH = useSelector(selectIsCHF);
    let thousandSeparator = isLanguageCH ? "'" : '.';
    let decimalSeparator = isLanguageCH ? ',' : ',';

    return (
        <div
            className={
                'fixed right-0 bottom-0 top-[84px] w-80 h-sidebarCalculatedValues p-6 flex z-20 flex-wrap content-between overflow-y-scroll ' +
                (isResultPage ? 'bg-black text-white border-l border-l-nav-border-grey' : 'bg-white shadow-custom')
            }
        >
            <div className="flex-auto">
                <div className="text-xl font-medium">
                    <Translator id="canvas.right.header.title" />
                </div>
                <div className="mb-10">
                    {lineValues &&
                        lineValues.map((data, i) => {
                            let line = JSON.parse(data);
                            return (
                                <ul key={`line-${i}`}>
                                    {line.map((element) => (
                                        <li key={element.id}>
                                            {element.label === 'Name' && (
                                                <div className="w-6 h-6 bg-white shadow-line-number rounded-full text-center font-medium mb-2 leading-7 mt-6 text-black">
                                                    {element.value}
                                                </div>
                                            )}
                                            {element.label !== 'Name' && (
                                                <>
                                                    {element.label}:<> </>
                                                    {element.suffix === 'mm' && (
                                                        <NumberFormat
                                                            value={element.value}
                                                            displayType={'text'}
                                                            thousandSeparator={thousandSeparator}
                                                            decimalSeparator={decimalSeparator}
                                                            decimalScale={0}
                                                            suffix={' mm'}
                                                        />
                                                    )}
                                                    {element.suffix !== 'mm' && <>{element.value}</>}
                                                </>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            );
                        })}
                </div>
                <div className="text-15 mb-5">
                    <p className="font-medium">
                        <Translator id="canvas.right.lumen.title" />
                    </p>
                    <p>
                        {overallLumen && (
                            <NumberFormat
                                value={overallLumen}
                                displayType={'text'}
                                thousandSeparator={thousandSeparator}
                                decimalSeparator={decimalSeparator}
                                decimalScale={0}
                                suffix={' lm'}
                            />
                        )}
                    </p>
                </div>
                <div className="text-15 mb-5">
                    <p className="font-medium">
                        <Translator id="canvas.right.wattage.title" />
                    </p>
                    <p>
                        {overallWatt && (
                            <NumberFormat
                                value={overallWatt.replace('.', ',')}
                                displayType={'text'}
                                thousandSeparator={thousandSeparator}
                                decimalSeparator={decimalSeparator}
                                decimalScale={2}
                                suffix={' W'}
                            />
                        )}
                    </p>
                </div>
                <div className="text-15 mb-5">
                    <p className="font-medium">
                        <Translator id="canvas.right.devices.title" />
                    </p>
                    <p>{operatingDevices && operatingDevices}</p>
                </div>
            </div>

            <div className="flex-auto">
                {productPriceChf && isLanguageCH && (
                    <p className="text-28 font-medium">
                        <NumberFormat
                            value={productPriceChf}
                            displayType={'text'}
                            thousandSeparator={thousandSeparator}
                            decimalSeparator={decimalSeparator}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            suffix={' CHF'}
                        />
                    </p>
                )}
                {productPriceEur && !isLanguageCH && (
                    <p className="text-28 font-medium">
                        <NumberFormat
                            value={productPriceEur}
                            displayType={'text'}
                            thousandSeparator={thousandSeparator}
                            decimalSeparator={decimalSeparator}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            suffix={' EUR'}
                        />
                    </p>
                )}
                {(productPriceEur || productPriceChf) && (
                    <p className="text-15">
                        <Translator id="canvas.right.price.info" />
                    </p>
                )}

                {!isResultPage && (
                    <Button
                        className={
                            'mt-6 text-white py-2 px-5 text-xl w-full ' +
                            (lineValues ? 'bg-black hover:brightness-75 duration-300' : 'bg-disabled-border-grey opacity-50')
                        }
                        disabled={lineValues ? '' : true}
                        onClick={() => {
                            dispatch(setShowSavingModal(!showSavingModal));
                        }}
                    >
                        <Translator id="canvas.right.finish.button" />
                    </Button>
                )}
                {showSavingModal && <SavingModal isResultPage={isResultPage} />}
                {isResultPage && (
                    <>
                        <Button
                            className="mt-4 text-white py-2 px-5 text-xl w-full border-white border hover:brightness-75 duration-300"
                            onClick={() => dispatch(setShowRequestForm(!showRequestForm))}
                        >
                            <Translator id="canvas.right.request.button" />
                        </Button>
                    </>
                )}
                {showRequestForm && <RequestForm isResultPage={isResultPage} />}
                {showLoadingModal && <LoadingModal action={'formSend'} />}
            </div>
        </div>
    );
}
