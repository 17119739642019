import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectIsCHF,
    selectProductPriceChf,
    selectProductPriceEur,
    selectRuleset,
    selectRulesetName,
    selectUserDiffusors,
    selectUserEQP,
    selectUserProducts,
    selectUserProfiles,
    selectUserSystemComponents,
    selectUserSystemEquipment,
} from '../features/products/productsSlice';
import { selectCountryCode, selectInstallation, setConfigurationProducts } from '../features/configuration/configurationSlice';
import {
    calculateDiffusorsWithQuantity,
    calculateEquipmentWithQuantity,
    calculateProductList,
    calculateProfilesWithQuantity,
    calculateSystemComponentsWithQuantity,
    calculateSystemEquipmentWithQuantity,
    SWISSCODE,
} from '../services/service';
import NumberFormat from 'react-number-format';

//images
import diffusorChannelS from '../assets/article-list/channel-s/diffusor.jpg';
import equipmentChannelS from '../assets/article-list/channel-s/equipment.jpg';
import lightingChannelS from '../assets/article-list/channel-s/lighting.jpg';
import profileChannelS from '../assets/article-list/channel-s/profile.jpg';
import diffusorPureliteSlim from '../assets/article-list/purelite-slim/diffusor.jpg';
import equipmentPureliteSlim from '../assets/article-list/purelite-slim/equipment.jpg';
import lightingPureliteSlim from '../assets/article-list/purelite-slim/lighting.jpg';
import profilePureliteSlim from '../assets/article-list/purelite-slim/profile.jpg';
import diffusorPurelite from '../assets/article-list/purelite/diffusor.jpg';
import equipmentPurelite from '../assets/article-list/purelite/equipment.jpg';
import lightingPurelite from '../assets/article-list/purelite/lighting.jpg';
import profilePurelite from '../assets/article-list/purelite/profile.jpg';
import diffusorFlow from '../assets/article-list/flow/diffusor.jpg';
import equipmentFlow from '../assets/article-list/flow/equipment.jpg';
import lightingFlow from '../assets/article-list/flow/lighting.jpg';
import profileFlow from '../assets/article-list/flow/profile.jpg';
import Translator, { imperativeTranslateMessage } from './Translator';
import { useIntl } from 'react-intl';
import { CS_RECESSED_CORNER_LEG, CS_STANDARD_CORNER_LEG } from '../features/products/channels';

/**
 * React component rendering the article list shown on the result screen.
 It shows section for profiles, lights and accessories with article code, article code and quantity.
 *
 * @export
 * @return {*}
 */
export default function ArticleList() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const userProducts = useSelector(selectUserProducts);
    const installation = useSelector(selectInstallation);
    let equipment = useSelector(selectUserEQP);
    let systemEquipment = useSelector(selectUserSystemEquipment);
    let systemComponents = useSelector(selectUserSystemComponents);
    let profiles = useSelector(selectUserProfiles);
    let diffusors = useSelector(selectUserDiffusors);
    let rulesetName = useSelector(selectRulesetName);

    equipment = equipment ? JSON.parse(equipment) : [];
    systemEquipment = systemEquipment ? JSON.parse(systemEquipment) : [];
    systemComponents = systemComponents ? JSON.parse(systemComponents) : [];
    profiles = profiles ? JSON.parse(profiles) : [];
    let productsWithQuantity = userProducts ? calculateProductList(userProducts) : [];
    let equipmentWithQuantity = equipment ? calculateEquipmentWithQuantity(equipment) : [];
    let systemEquipmentWithQuantity = systemEquipment ? calculateSystemEquipmentWithQuantity(systemEquipment) : [];
    let systemComponentsWithQuantity = systemComponents ? calculateSystemComponentsWithQuantity(systemComponents) : [];
    let profilesWithQuantity = profiles ? calculateProfilesWithQuantity(profiles) : [];
    let diffusorsWithQuantity = diffusors ? calculateDiffusorsWithQuantity(JSON.parse(diffusors)) : [];

    let productCount = 0;

    // Country specific formatting
    const countryCode = useSelector(selectCountryCode);
    let isLanguageCH = useSelector(selectIsCHF);
    let thousandSeparator = isLanguageCH ? "'" : '.';
    let decimalSeparator = isLanguageCH ? ',' : ',';

    let profileImage = profileChannelS;
    let lightingImage = lightingChannelS;
    let diffusorImage = diffusorChannelS;
    let equipmentImage = equipmentChannelS;

    dispatch(setConfigurationProducts(userProducts));
    switch (rulesetName) {
        case 'Channels S':
            profileImage = profileChannelS;
            lightingImage = lightingChannelS;
            diffusorImage = diffusorChannelS;
            equipmentImage = equipmentChannelS;
            break;
        case 'Purelite Slim':
            profileImage = profilePureliteSlim;
            lightingImage = lightingPureliteSlim;
            diffusorImage = diffusorPureliteSlim;
            equipmentImage = equipmentPureliteSlim;
            break;
        case 'Purelite':
            profileImage = profilePurelite;
            lightingImage = lightingPurelite;
            diffusorImage = diffusorPurelite;
            equipmentImage = equipmentPurelite;
            break;
        case 'Flow':
            profileImage = profileFlow;
            lightingImage = lightingFlow;
            diffusorImage = diffusorFlow;
            equipmentImage = equipmentFlow;
            break;

        default:
            profileImage = profileChannelS;
            lightingImage = lightingChannelS;
            diffusorImage = diffusorChannelS;
            equipmentImage = equipmentChannelS;
            break;
    }

    return (
        <>
            {profiles && (
                <div>
                    <h2 className="text-28">
                        <Translator id="overview.articles.table.profiles.header" />
                    </h2>
                    <div className="flex flex-wrap">
                        <div className="flex-articlelist-image">
                            <img width="150" src={profileImage} alt={imperativeTranslateMessage(intl, 'overview.articles.table.profiles.header')} />
                        </div>
                        <div className="flex-articlelist-table pl-4">
                            <table className="table-auto w-full">
                                <thead>
                                    <tr>
                                        <td className="p-2 border-bottom-2">
                                            <Translator id="overview.articles.table.article.title" />
                                        </td>
                                        <td className="p-2 border-bottom-2">
                                            <Translator id="overview.articles.table.article.number" />
                                        </td>
                                        <td className="p-2 border-bottom-2">
                                            <Translator id="overview.articles.table.article.count" />
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {equipmentWithQuantity.map((item) =>
                                        // Display the corner profile which are always part of equipment as part of profile
                                        {
                                            if (item.product.article_nr === '2004.7100' || item.product.article_nr === '2004.7098') {
                                                return (
                                                    <tr key={productCount++}>
                                                        <td className="p-2 border-bottom-2">
                                                            <strong>{item.product.article_code}</strong>
                                                            <br />
                                                            {!item.cut && (
                                                                <>
                                                                    <Translator id="overview.articles.table.article.length" />{' '}
                                                                    <NumberFormat
                                                                        value={installation === 'recessed' ? CS_RECESSED_CORNER_LEG : CS_STANDARD_CORNER_LEG}
                                                                        displayType={'text'}
                                                                        thousandSeparator={thousandSeparator}
                                                                        decimalSeparator={decimalSeparator}
                                                                        decimalScale={0}
                                                                        suffix={' mm'}
                                                                    />
                                                                    <br />
                                                                </>
                                                            )}
                                                        </td>
                                                        <td className="p-2 border-bottom-2 w-32">{item.product.article_nr}</td>
                                                        <td className="p-2 border-bottom-2 w-32">{item.quantity}</td>
                                                    </tr>
                                                );
                                            }
                                        }
                                    )}

                                    {profilesWithQuantity.map((item) => (
                                        <tr key={productCount++}>
                                            <td className="p-2 border-bottom-2">
                                                <strong>{item.product.article_code}</strong>
                                                <br />
                                                {item.cut && (
                                                    <>
                                                        <Translator id="overview.articles.table.article.length" />{' '}
                                                        <NumberFormat
                                                            value={item.cut}
                                                            displayType={'text'}
                                                            thousandSeparator={thousandSeparator}
                                                            decimalSeparator={decimalSeparator}
                                                            decimalScale={0}
                                                            suffix={' mm'}
                                                        />
                                                        <br />
                                                    </>
                                                )}
                                                {!item.cut && (
                                                    <>
                                                        <Translator id="overview.articles.table.article.length" />{' '}
                                                        <NumberFormat
                                                            value={item.product.length}
                                                            displayType={'text'}
                                                            thousandSeparator={thousandSeparator}
                                                            decimalSeparator={decimalSeparator}
                                                            decimalScale={0}
                                                            suffix={' mm'}
                                                        />
                                                        <br />
                                                    </>
                                                )}
                                            </td>
                                            <td className="p-2 border-bottom-2 w-32">{item.product.article_nr}</td>
                                            <td className="p-2 border-bottom-2 w-32">{item.quantity}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}

            {userProducts && (
                <div className="mt-32">
                    <h2 className="text-28">
                        <Translator id="overview.articles.table.lights.header" />
                    </h2>
                    <div className="flex flex-wrap">
                        <div className="flex-articlelist-image">
                            <img width="150" src={lightingImage} alt={imperativeTranslateMessage(intl, 'overview.articles.table.lights.header')} />
                        </div>
                        <div className="flex-articlelist-table pl-4">
                            <table className="table-auto w-full">
                                <thead>
                                    <tr>
                                        <td className="p-2 border-bottom-2">
                                            <Translator id="overview.articles.table.article.title" />
                                        </td>
                                        <td className="p-2 border-bottom-2">
                                            <Translator id="overview.articles.table.article.number" />
                                        </td>
                                        <td className="p-2 border-bottom-2">
                                            <Translator id="overview.articles.table.article.count" />
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productsWithQuantity.map((line) => (
                                        <tr key={productCount++}>
                                            <td className="p-2 border-bottom-2">
                                                <strong>{line.product.article_code}</strong>
                                                <br />
                                                <Translator id="overview.articles.table.article.length" />{' '}
                                                <NumberFormat
                                                    value={line.product.length}
                                                    displayType={'text'}
                                                    thousandSeparator={thousandSeparator}
                                                    decimalSeparator={decimalSeparator}
                                                    decimalScale={0}
                                                    suffix={' mm'}
                                                />
                                                <br />
                                            </td>
                                            <td className="p-2 border-bottom-2 w-32">{line.product.article_nr}</td>
                                            <td className="p-2 border-bottom-2 w-32">{line.quantity}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}

            {diffusors && (
                <div className="mt-32">
                    <h2 className="text-28">
                        <Translator id="overview.articles.table.diffusor.header" />
                    </h2>
                    <div className="flex flex-wrap">
                        <div className="flex-articlelist-image">
                            <img width="150" src={diffusorImage} alt={imperativeTranslateMessage(intl, 'overview.articles.table.diffusor.header')} />
                        </div>
                        <div className="flex-articlelist-table pl-4">
                            <table className="table-auto w-full">
                                <thead>
                                    <tr>
                                        <td className="p-2 border-bottom-2">
                                            <Translator id="overview.articles.table.article.title" />
                                        </td>
                                        <td className="p-2 border-bottom-2">
                                            <Translator id="overview.articles.table.article.number" />
                                        </td>
                                        <td className="p-2 border-bottom-2">
                                            <Translator id="overview.articles.table.article.count" />
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {diffusorsWithQuantity.map((item) => (
                                        <tr key={productCount++}>
                                            <td className="p-2 border-bottom-2">
                                                <strong>{item.product.article_code}</strong>
                                                <br />
                                                <br />
                                                <Translator id="overview.articles.table.article.length" />{' '}
                                                <NumberFormat
                                                    value={item.product.length}
                                                    displayType={'text'}
                                                    thousandSeparator={thousandSeparator}
                                                    decimalSeparator={decimalSeparator}
                                                    decimalScale={0}
                                                    suffix={' mm'}
                                                />
                                                <br />
                                            </td>
                                            <td className="p-2 border-bottom-2 w-32">{item.product.article_nr}</td>
                                            <td className="p-2 border-bottom-2 w-32">{item.quantity}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}

            {(equipment || systemEquipment || systemComponents) && (
                <div className="mt-32">
                    <h2 className="text-28">
                        <Translator id="overview.articles.table.accessories.header" />
                    </h2>
                    <div className="flex flex-wrap">
                        <div className="flex-articlelist-image">
                            <img width="150" src={equipmentImage} alt={imperativeTranslateMessage(intl, 'overview.articles.table.accessories.header')} />
                        </div>
                        <div className="flex-articlelist-table pl-4">
                            <table className="table-auto w-full">
                                <thead>
                                    <tr>
                                        <td className="p-2 border-bottom-2">
                                            <Translator id="overview.articles.table.article.title" />
                                        </td>
                                        <td className="p-2 border-bottom-2">
                                            <Translator id="overview.articles.table.article.number" />
                                        </td>
                                        <td className="p-2 border-bottom-2">
                                            <Translator id="overview.articles.table.article.count" />
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {systemEquipment && (
                                        <>
                                            {systemEquipmentWithQuantity.map((item) => (
                                                <tr key={productCount++}>
                                                    <td className="p-2 border-bottom-2">
                                                        <strong>{item.product.article_code}</strong>
                                                    </td>
                                                    <td className="p-2 border-bottom-2 w-32">{item.product.article_nr}</td>
                                                    <td className="p-2 border-bottom-2 w-32">{item.quantity}</td>
                                                </tr>
                                            ))}
                                        </>
                                    )}
                                    {equipment && (
                                        <>
                                            {equipmentWithQuantity.map((item) =>
                                                // Remove corner profiles from equipment list
                                                {
                                                    if (item.product.article_nr !== '2004.7100' && item.product.article_nr !== '2004.7098') {
                                                        return (
                                                            <tr key={productCount++}>
                                                                <td className="p-2 border-bottom-2">
                                                                    <strong>{item.product.article_code}</strong>
                                                                </td>
                                                                <td className="p-2 border-bottom-2 w-32">{item.product.article_nr}</td>
                                                                <td className="p-2 border-bottom-2 w-32">{item.quantity}</td>
                                                            </tr>
                                                        );
                                                    }
                                                }
                                            )}
                                        </>
                                    )}
                                    {systemComponents && (
                                        <>
                                            {systemComponentsWithQuantity.map((item) => (
                                                <tr key={productCount++}>
                                                    <td className="p-2 border-bottom-2">
                                                        <strong>{item.product.article_code}</strong>
                                                        <br />
                                                        <br />
                                                        <Translator id="overview.articles.table.article.length" />{' '}
                                                        <NumberFormat
                                                            value={item.product.length}
                                                            displayType={'text'}
                                                            thousandSeparator={thousandSeparator}
                                                            decimalSeparator={decimalSeparator}
                                                            decimalScale={0}
                                                            suffix={' mm'}
                                                        />
                                                        <br />
                                                    </td>
                                                    <td className="p-2 border-bottom-2 w-32">{item.product.article_nr}</td>
                                                    <td className="p-2 border-bottom-2 w-32">{item.quantity}</td>
                                                </tr>
                                            ))}
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
