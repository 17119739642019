import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectConfigurationID,
    selectCountryCode,
    selectForm,
    selectProjectAuthor,
    selectProjectBuilding,
    selectProjectFloor,
    selectProjectLine,
    selectProjectName,
    selectProjectRoom,
    selectPureliteSlimLighting,
    selectRulesetID,
    setProjectAuthor,
    setProjectBuilding,
    setProjectFloor,
    setProjectLine,
    setProjectName,
    setProjectRoom,
} from '../features/configuration/configurationSlice';
import { handleCenterCanvas, loadSingleRuleset, renderRulesetImg, SWISSCODE } from '../services/service';
import SidebarCalculatedValues from '../components/SidebarCalculatedValues';
import ResultTab from '../components/ResultTab';
import {
    selectIsCHF,
    selectOperatingDevices,
    selectOverallLumen,
    selectOverallWatt,
    selectResultSVG,
    selectRulesetName,
    selectShowDownloadModal,
    setLoadingFinished,
    setShowSavingModal,
} from '../features/products/productsSlice';
import NumberFormat from 'react-number-format';
import { UncontrolledReactSVGPanZoom } from 'react-svg-pan-zoom';
import AutoSizer from 'react-virtualized-auto-sizer';
import CanvasMiniature from '../components/CanvasMiniature';
import CanvasToolbar from '../components/CanvasToolbar';
import canvasSVG from '../assets/canvas-raster-bg.svg';
import { useBackListener } from '../services/useBackListener';
import LoadingModal from '../components/LoadingModal';
import Translator from '../components/Translator';

export default function Result() {
    const dispatch = useDispatch();

    const [currentRuleset, setCurrentRuleset] = useState(null);
    const [codeIsCopied, setCodeIsCopied] = useState(null);

    const backgroundStyle = {
        backgroundImage: `url(${canvasSVG}`,
    };

    const Viewer = useRef(null);
    const group = useRef(null);

    const currentRulesetID = useSelector(selectRulesetID);
    const configurationID = useSelector(selectConfigurationID);
    const overallLumen = useSelector(selectOverallLumen);
    const overallWatt = useSelector(selectOverallWatt);
    const operatingDevices = useSelector(selectOperatingDevices);
    const resultSVG = useSelector(selectResultSVG);
    const currentRulesetName = useSelector(selectRulesetName);
    const showDownloadModal = useSelector(selectShowDownloadModal);
    const pureliteSlimLighting = useSelector(selectPureliteSlimLighting);
    const form = useSelector(selectForm);

    const projectName = useSelector(selectProjectName) || 'Unbenanntes Projekt';
    const projectAuthor = useSelector(selectProjectAuthor) || 'Anonym';
    const projectBuilding = useSelector(selectProjectBuilding) || 'Unbenanntes Gebäude';
    const projectFloor = useSelector(selectProjectFloor) || 'Unbenanntes Stockwerk';
    const projectRoom = useSelector(selectProjectRoom) || 'Unbenannter Raum';
    const projectLine = useSelector(selectProjectLine) || 'Unbenannte Linie';

    if (projectName === 'Unbenanntes Projekt') dispatch(setProjectName('Unbenanntes Projekt'));
    if (projectAuthor === 'Anonym') dispatch(setProjectAuthor('Anonym'));
    if (projectBuilding === 'Unbenanntes Gebäude') dispatch(setProjectBuilding('Unbenanntes Gebäude'));
    if (projectFloor === 'Unbenanntes Stockwerk') dispatch(setProjectFloor('Unbenanntes Stockwerk'));
    if (projectRoom === 'Unbenannter Raum') dispatch(setProjectRoom('Unbenannter Raum'));
    if (projectLine === 'Unbenannte Linie') dispatch(setProjectLine('Unbenannte Linie'));

    useEffect(() => {
        loadSingleRuleset({ id: currentRulesetID }).then((result) => {
            setCurrentRuleset(result.data);
        });
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (resultSVG && group && Viewer) {
                handleCenterCanvas(group, Viewer, 25, form);
            }
        }, 100);
    }, [resultSVG, group, Viewer]);

    //Listener for Browser Back Function
    useBackListener();

    // Country specific formatting
    const countryCode = useSelector(selectCountryCode);
    let isLanguageCH = useSelector(selectIsCHF);
    let thousandSeparator = isLanguageCH ? "'" : '.';
    let decimalSeparator = isLanguageCH ? ',' : ',';

    return (
        <>
            <div className="mt-12 text-left">
                <div className="mr-24 result-container">
                    <Link
                        to="/inputscreen"
                        className="font-medium text-18"
                        onClick={() => {
                            // Reset the cached downloads if the user goes backward to the canvas because if he returns to result screen a new configuration will be saved
                            // TODO: Same must be done to catch user using backwards navigation arrows of browser
                            dispatch(setLoadingFinished(false));
                            dispatch(setShowSavingModal(false));
                        }}
                    >
                        <FontAwesomeIcon icon={['far', 'angle-left']} className="pr-2" />
                        <Translator id="overview.back.button" />
                    </Link>

                    <div className="text-4xl font-medium mt-20">
                        <Translator id="overview.project.header" />
                        <span onClick={() => dispatch(setShowSavingModal(true))} className="cursor-pointer transition-opacity duration-300 hover:opacity-80">
                            <span className="w-6 h-6 inline-block text-center rounded-full bg-white relative align-text-top ml-3 mt-0.5">
                                <FontAwesomeIcon icon={['far', 'pen']} className="text-14 text-black relative -top-3" />
                            </span>
                            <span className="text-14 ml-2 align-middle">
                                <Translator id="overview.project.edit.button" />
                            </span>
                        </span>
                    </div>
                    <div className="text-28 mt-2 mb-10">
                        {projectName} <Translator id="overview.project.ownership.phrase" /> {projectAuthor}
                    </div>

                    <div className="rulesets grid grid-cols-2 gap-8">
                        <div className="border-2 relative transition ease-in-out border-white">
                            <img className="object-contain" src={renderRulesetImg(currentRulesetName, true, pureliteSlimLighting, 'result')} alt="" />
                            <div className="absolute left-6 bottom-2 text-left">
                                <h4 className="text-h4 font-medium leading-10">{currentRuleset && currentRuleset.name}</h4>
                                <p className="text-28">
                                    <Translator id="overview.system.card.image.subheader.title" /> |{' '}
                                    <Translator id="overview.system.card.image.subheader.code" /> <span className="font-medium">{configurationID}</span>
                                    <span
                                        className="text-14 h-6 inline-block text-black align-middle ml-3 -mt-[3px] cursor-pointer hover:opacity-80 transition-opacity duration-300"
                                        onClick={() => {
                                            navigator.clipboard.writeText(configurationID);
                                            setCodeIsCopied(true);
                                            setTimeout(() => setCodeIsCopied(false), 3000);
                                        }}
                                    >
                                        <span className="w-6 h-6 inline-block text-center rounded-full bg-white relative -top-[2px]">
                                            <FontAwesomeIcon icon={['far', 'copy']} className="align-text-bottom" />
                                        </span>
                                        <span className="text-white ml-2 text-12">
                                            {!codeIsCopied ? <Translator id="overview.system.card.image.copy.button" /> : null}
                                            {codeIsCopied ? <FontAwesomeIcon icon={['far', 'check']} /> : null}
                                        </span>
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="relative transition ease-in-out bg-white" style={backgroundStyle}>
                            <AutoSizer>
                                {({ height, width }) => (
                                    <UncontrolledReactSVGPanZoom
                                        ref={Viewer}
                                        height={height}
                                        width={width}
                                        background={'transparent'}
                                        SVGBackground={'transparent'}
                                        customMiniature={CanvasMiniature}
                                        customToolbar={CanvasToolbar}
                                        detectAutoPan={false}
                                        detectWheel={false}
                                        detectPinchGesture={false}
                                    >
                                        <svg width={width} height={height}>
                                            <g fillOpacity="1" strokeWidth="1" ref={group}>
                                                {resultSVG && <g dangerouslySetInnerHTML={{ __html: resultSVG }} />}
                                            </g>
                                        </svg>
                                    </UncontrolledReactSVGPanZoom>
                                )}
                            </AutoSizer>
                        </div>
                    </div>

                    <div className="mt-24">
                        <p className="text-36 font-medium">
                            <Translator id="overview.properties.header" />
                        </p>
                        <div className="grid grid-cols-3 mt-12">
                            <div className="flex flex-wrap">
                                <div className="bg-white rounded-full text-black w-12 h-12 flex justify-center items-center text-18 mr-5">
                                    <FontAwesomeIcon icon={['far', 'battery-bolt']} />
                                </div>
                                <div>
                                    <p className="text-15 font-book">
                                        <Translator id="overview.properties.lumen.title" /> [lm]
                                    </p>
                                    <p className="text-18 font-medium mt-2">
                                        {overallLumen && (
                                            <NumberFormat
                                                value={overallLumen}
                                                displayType={'text'}
                                                thousandSeparator={thousandSeparator}
                                                decimalSeparator={decimalSeparator}
                                                decimalScale={0}
                                                suffix={' lm'}
                                            />
                                        )}
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-wrap">
                                <div className="bg-white rounded-full text-black w-12 h-12 flex justify-center items-center text-18 mr-5">
                                    <FontAwesomeIcon icon={['far', 'square-bolt']} />
                                </div>
                                <div>
                                    <p className="text-15 font-book">
                                        <Translator id="overview.properties.wattage.title" />
                                    </p>
                                    <p className="text-18 font-medium mt-2">
                                        {overallWatt && (
                                            <NumberFormat
                                                value={overallWatt.replace('.', ',')}
                                                displayType={'text'}
                                                thousandSeparator={thousandSeparator}
                                                decimalSeparator={decimalSeparator}
                                                decimalScale={2}
                                                suffix={' W'}
                                            />
                                        )}
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-wrap">
                                <div className="bg-white rounded-full text-black w-12 h-12 flex justify-center items-center text-18 mr-5">
                                    <FontAwesomeIcon icon={['far', 'grid-dividers']} />
                                </div>
                                <div>
                                    <p className="text-15 font-book">
                                        <Translator id="overview.properties.devices.title" />
                                    </p>
                                    <p className="text-18 font-medium mt-2">{operatingDevices && operatingDevices}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ResultTab className="mt-40" />
                    {showDownloadModal && <LoadingModal action="downloads" />}
                </div>
                <SidebarCalculatedValues isResultPage={true} />
            </div>
        </>
    );
}
