import { setOverallLumen } from "../features/products/productsSlice.js";
import { ROTATION_OF_LINE_ELEMENTS } from "./service.js";

/**
 *
 * @param {*} lineItems
 * @param {*} combiProducts
 * @param {*} power
 * @param {*} diffusor
 * @param {*} imagesContainer
 * @param {*} cornerAtElement
 * @param {*} lights
 * @param {*} colorTemperature
 * @returns {Array} At 0: light setup data array; at 1: list of corners; at 2: images array
 */

// At the moment all corn
export const uldCornerLength = () => {
    // 135mm
    return 0.205;
    // 145mm
    // return 0.215;
};
export const setupLightData = (lineItems, combiProducts, power, diffusor, imagesContainer, m3DContainer, cornerAtElement, lights, colorTemperature) => {
    Object.entries(lineItems).map(([key, item]) => {
        let combinationProduct = combiProducts.filter(
            (el) => el.article_code === item.article_code && el.power === power.name && el.diffusor.toLowerCase() === diffusor
        );
        let itemLDT = getLDT(combinationProduct, item);
        let itemM3D = getM3D(combinationProduct, item);
        let leo = "LEO Fläche";

        // Get the length of each element (x, z taken from the excel?)
        let itemSizeX = 65 / 1000; // Breite
        let itemSizeY = item.length / 1000; // Länge
        let itemSizeZ = 60 / 1000; // Höhe

        imagesContainer.push(itemLDT);

        if (item.type === "corner") {
            cornerAtElement.push(key);
            leo = "LEO Horizontal";
        }
        m3DContainer.push(itemM3D);

        // Create the current item
        lights.push(
            createDialuxItem(
                itemSizeX,
                itemSizeY,
                itemSizeZ,
                colorTemperature,
                getLumen(combinationProduct, item),
                getWatt(combinationProduct, item),
                getLDT(combinationProduct, item),
                itemM3D,
                item.type === "corner" ? true : false,
                leo
            )
        );
    });
    return [lights, cornerAtElement, imagesContainer, m3DContainer];
};

export const dialuxI = (endProducts, images, lineItems, combiProducts, colorTemperature, power, diffusor) => {
    let imagesContainer = []; //
    let m3dsContainer = []; //
    let lights = []; // Setups of all the product data to send them to the line calculators
    let cornerAtElement = []; // Holds at which index a corner was encountered
    let corners = {}; // Contains corner index and the type of corner encountered | Not necessary for I-Form because there are no corners
    let finishedLightObjects = []; // Contains finished array with correctly calculated and placed elements and their meta data
    let lastPosition = {
        x: 0,
        y: 0,
        minX: 0,
        minY: 0,
        maxX: 0,
        maxY: 0,
    }; // Determines position were the next line starts

    let setupData = setupLightData(lineItems, combiProducts, power, diffusor, imagesContainer, m3dsContainer, cornerAtElement, lights, colorTemperature);
    lights = setupData[0];
    cornerAtElement = setupData[1];
    imagesContainer = setupData[2];
    m3dsContainer = setupData[3];

    finishedLightObjects = lineRight(lights, lastPosition, false);
    return [finishedLightObjects, imagesContainer, m3dsContainer];
};

export const dialuxL = (endProducts, images, lineItems, combiProducts, colorTemperature, power, diffusor) => {
    let imagesContainer = []; //
    let m3dsContainer = []; //
    let lights = []; // Setups of all the product data to send them to the line calculators
    let cornerAtElement = []; // Holds at which index a corner was encountered
    let corners = {}; // Contains corner index and the type of corner encountered
    let finishedLightObjects = []; // Contains finished array with correctly calculated and placed elements and their meta data
    let lastPosition = {
        x: 0,
        y: 0,
        minX: 0,
        minY: 0,
        maxX: 0,
        maxY: 0,
    }; // Determines position were the next line starts

    let setupData = setupLightData(lineItems, combiProducts, power, diffusor, imagesContainer, m3dsContainer, cornerAtElement, lights, colorTemperature);
    lights = setupData[0];
    cornerAtElement = setupData[1];
    imagesContainer = setupData[2];
    m3dsContainer = setupData[3];

    for (let i = 0; i < cornerAtElement.length; i++) {
        switch (i) {
            case 0:
                corners[cornerAtElement[i]] = "L";
                break;
            default:
            case 1:
                corners[cornerAtElement[i]] = "L";
                break;
        }
    }

    let a = lights.slice(0, parseInt(cornerAtElement[0]) + 1);
    let b = lights.slice(parseInt(cornerAtElement[0]) + 1, lights.length);

    let downElements = lineDown(a, lastPosition, corners);
    let rightElements = lineRight(b, lastPosition, []);
    finishedLightObjects = downElements.concat(rightElements);

    return [finishedLightObjects, imagesContainer, m3dsContainer];
};

export const dialuxU = (endProducts, images, lineItems, combiProducts, colorTemperature, power, diffusor) => {
    let imagesContainer = []; //
    let m3dsContainer = []; //
    let lights = []; // Setups of all the product data to send them to the line calculators
    let cornerAtElement = []; // Holds at which index a corner was encountered
    let corners = {}; // Contains corner index and the type of corner encountered
    let finishedLightObjects = []; // Contains finished array with correctly calculated and placed elements and their meta data
    let lastPosition = {
        x: 0,
        y: 0,
        minX: 0,
        minY: 0,
        maxX: 0,
        maxY: 0,
    }; // Determines position were the next line starts

    let setupData = setupLightData(lineItems, combiProducts, power, diffusor, imagesContainer, m3dsContainer, cornerAtElement, lights, colorTemperature);
    lights = setupData[0];
    cornerAtElement = setupData[1];
    imagesContainer = setupData[2];
    m3dsContainer = setupData[3];

    for (let i = 0; i < cornerAtElement.length; i++) {
        switch (i) {
            case 0:
                corners[cornerAtElement[i]] = "L";
                break;
            default:
            case 1:
                corners[cornerAtElement[i]] = "L";
                break;
        }
    }

    let a = lights.slice(0, parseInt(cornerAtElement[0]) + 1);
    let b = lights.slice(parseInt(cornerAtElement[0]) + 1, parseInt(cornerAtElement[1]) + 1);
    let c = lights.slice(parseInt(cornerAtElement[1]) + 1, lights.length);

    // Remove and reposition corners used for the last segment
    let downElements = lineDown(a, lastPosition, corners);
    corners = changeProcessedCorners(corners, a);
    let rightElements = lineRight(b, lastPosition, corners);
    corners = changeProcessedCorners(corners, b);
    let upElements = lineUp(c, lastPosition, []);
    corners = changeProcessedCorners(corners, c);

    finishedLightObjects = downElements.concat(rightElements);
    finishedLightObjects = finishedLightObjects.concat(upElements);

    return [finishedLightObjects, imagesContainer, m3dsContainer];
};

export const dialuxZ = (endProducts, images, lineItems, combiProducts, colorTemperature, power, diffusor) => {
    let imagesContainer = []; //
    let m3dsContainer = []; //
    let lights = []; // Setups of all the product data to send them to the line calculators
    let cornerAtElement = []; // Holds at which index a corner was encountered
    let corners = {}; // Contains corner index and the type of corner encountered
    let finishedLightObjects = []; // Contains finished array with correctly calculated and placed elements and their meta data
    let lastPosition = {
        x: 0,
        y: 0,
        minX: 0,
        minY: 0,
        maxX: 0,
        maxY: 0,
    }; // Determines position were the next line starts

    let setupData = setupLightData(lineItems, combiProducts, power, diffusor, imagesContainer, m3dsContainer, cornerAtElement, lights, colorTemperature);
    lights = setupData[0];
    cornerAtElement = setupData[1];
    imagesContainer = setupData[2];
    m3dsContainer = setupData[3];

    for (let i = 0; i < cornerAtElement.length; i++) {
        switch (i) {
            case 0:
                corners[cornerAtElement[i]] = "R";
                break;
            default:
            case 1:
                corners[cornerAtElement[i]] = "L";
                break;
        }
    }

    let a = lights.slice(0, parseInt(cornerAtElement[0]) + 1);
    let b = lights.slice(parseInt(cornerAtElement[0]) + 1, parseInt(cornerAtElement[1]) + 1);
    let c = lights.slice(parseInt(cornerAtElement[1]) + 1, lights.length);

    // Remove and reposition corners used for the last segment
    let downElements = lineRight(a, lastPosition, corners);
    corners = changeProcessedCorners(corners, a);
    let rightElements = lineDown(b, lastPosition, corners);
    corners = changeProcessedCorners(corners, b);
    let upElements = lineRight(c, lastPosition, []);
    corners = changeProcessedCorners(corners, c);

    finishedLightObjects = downElements.concat(rightElements);
    finishedLightObjects = finishedLightObjects.concat(upElements);

    return [finishedLightObjects, imagesContainer, m3dsContainer];
};

export const dialuxS = (endProducts, images, lineItems, combiProducts, colorTemperature, power, diffusor) => {
    let imagesContainer = []; //
    let m3dsContainer = []; //
    let lights = []; // Setups of all the product data to send them to the line calculators
    let cornerAtElement = []; // Holds at which index a corner was encountered
    let corners = {}; // Contains corner index and the type of corner encountered
    let finishedLightObjects = []; // Contains finished array with correctly calculated and placed elements and their meta data
    let lastPosition = {
        x: 0,
        y: 0,
        minX: 0,
        minY: 0,
        maxX: 0,
        maxY: 0,
    }; // Determines position were the next line starts

    let setupData = setupLightData(lineItems, combiProducts, power, diffusor, imagesContainer, m3dsContainer, cornerAtElement, lights, colorTemperature);
    lights = setupData[0];
    cornerAtElement = setupData[1];
    imagesContainer = setupData[2];
    m3dsContainer = setupData[3];

    for (let i = 0; i < cornerAtElement.length; i++) {
        switch (i) {
            case 0:
                corners[cornerAtElement[i]] = "L";
                break;
            default:
            case 1:
                corners[cornerAtElement[i]] = "R";
                break;
        }
    }

    let a = lights.slice(0, parseInt(cornerAtElement[0]) + 1);
    let b = lights.slice(parseInt(cornerAtElement[0]) + 1, parseInt(cornerAtElement[1]) + 1);
    let c = lights.slice(parseInt(cornerAtElement[1]) + 1, lights.length);

    // Remove and reposition corners used for the last segment
    let downElements = lineRight(a, lastPosition, corners);
    corners = changeProcessedCorners(corners, a);
    let rightElements = lineUp(b, lastPosition, corners);
    corners = changeProcessedCorners(corners, b);
    let upElements = lineRight(c, lastPosition, []);
    corners = changeProcessedCorners(corners, c);

    finishedLightObjects = downElements.concat(rightElements);
    finishedLightObjects = finishedLightObjects.concat(upElements);

    return [finishedLightObjects, imagesContainer, m3dsContainer];
};

export const dialuxO = (endProducts, images, lineItems, combiProducts, colorTemperature, power, diffusor) => {
    let imagesContainer = []; //
    let m3dsContainer = []; //
    let lights = []; // Setups of all the product data to send them to the line calculators
    let cornerAtElement = []; // Holds at which index a corner was encountered
    let corners = {}; // Contains corner index and the type of corner encountered
    let finishedLightObjects = []; // Contains finished array with correctly calculated and placed elements and their meta data
    let lastPosition = {
        x: 0,
        y: 0,
        minX: 0,
        minY: 0,
        maxX: 0,
        maxY: 0,
    }; // Determines position were the next line starts

    let setupData = setupLightData(lineItems, combiProducts, power, diffusor, imagesContainer, m3dsContainer, cornerAtElement, lights, colorTemperature);
    lights = setupData[0];
    cornerAtElement = setupData[1];
    imagesContainer = setupData[2];
    m3dsContainer = setupData[3];

    for (let i = 0; i < cornerAtElement.length; i++) {
        switch (i) {
            case 0:
                corners[cornerAtElement[i]] = "R";
                break;
            case 1:
                corners[cornerAtElement[i]] = "R";
                break;
            case 2:
                corners[cornerAtElement[i]] = "R";
                break;
            default:
            case 3:
                corners[cornerAtElement[i]] = "R";
                break;
        }
    }

    let a = lights.slice(0, parseInt(cornerAtElement[0]) + 1);
    let b = lights.slice(parseInt(cornerAtElement[0]) + 1, parseInt(cornerAtElement[1]) + 1);
    let c = lights.slice(parseInt(cornerAtElement[1]) + 1, parseInt(cornerAtElement[2]) + 1);
    let d = lights.slice(parseInt(cornerAtElement[2]) + 1, parseInt(cornerAtElement[3]) + 1);
    let e = lights.slice(parseInt(cornerAtElement[3]) + 1, lights.length);

    // Remove and reposition corners used for the last segment
    let rightElements = lineRight(a, lastPosition, corners);
    corners = changeProcessedCorners(corners, a);
    let downElements = lineDown(b, lastPosition, corners);
    corners = changeProcessedCorners(corners, b);
    let leftElements = lineLeft(c, lastPosition, corners);
    corners = changeProcessedCorners(corners, c);

    let upElements = lineUp(d, lastPosition, corners);
    corners = changeProcessedCorners(corners, d);

    let restRightElements = lineRight(e, lastPosition, []);
    corners = changeProcessedCorners(corners, e);

    finishedLightObjects = rightElements.concat(downElements);
    finishedLightObjects = finishedLightObjects.concat(leftElements);
    finishedLightObjects = finishedLightObjects.concat(upElements);
    finishedLightObjects = finishedLightObjects.concat(restRightElements);

    return [finishedLightObjects, imagesContainer, m3dsContainer];
};

export const lineRight = (lineItems, lastPosition, corners) => {
    let lastPositionX = lastPosition.x; // Start from last x-position
    for (let i = 0; i < lineItems.length; i++) {
        // Reset values from calculation and rotation 90° on z
        lineItems[i]["PositionY"] = lastPosition.y;
        lineItems[i]["RotationZ"] = 90;

        if (i === 0) {
            lineItems[i]["PositionX"] = lastPositionX + lineItems[i]["SizeY"] / 2;
        } else {
            // Additional segment's middle point is half the length of the current + last segment below the last segment's middle point
            lineItems[i]["PositionX"] = lastPositionX + (lineItems[i - 1]["SizeY"] + lineItems[i]["SizeY"]) / 2;
        }
        lastPositionX = lineItems[i]["PositionX"];
        lastPosition.x = lineItems[i]["PositionX"];
        lastPosition.y = lineItems[i]["PositionY"];
        setOffsetVertical(lastPosition, lineItems[i]);

        // Prepare next lights to be moved according to the corner type (only left|right possible)
        for (let corner in corners) {
            if (corner === i.toString()) {
                // lastPosition.x -=  (lineItems[i]["SizeY"] * 0.5 + lineItems[i]["SizeX"] * 0.5);
                if (corners[corner] == "L") {
                    lastPosition.y += uldCornerLength() / 2;
                } else {
                    // Right curve must be rotated for correct orientation
                    lineItems[i]["RotationZ"] = 180;
                    lastPosition.y -= uldCornerLength() / 2;
                }
            }
        }
    }
    return lineItems;
};

/**
 *
 * @param {*} lineItems
 * @param {*} lastPosition
 * @param {*} corners
 * @returns stub to move upwards
 */
export const lineUp = (lineItems, lastPosition, corners) => {
    let lastPositionY = lastPosition.y;
    for (let i = 0; i < lineItems.length; i++) {
        // Reset values from calculation and rotation 90° on z
        lineItems[i]["PositionY"] = lastPosition.y;
        lineItems[i]["PositionX"] = lastPosition.x;
        lineItems[i]["RotationZ"] = 0; // Zero rotation means the line goes vertical

        // Moving from segment to segment
        // If first element: do not apply the length of the previous light
        if (i === 0) {
            lineItems[i]["PositionY"] = lastPositionY + lineItems[i]["SizeY"] / 2;
        } else {
            // Additional segment's middle point is half the length of the current + last segment below the last segment's middle point
            lineItems[i]["PositionY"] = lastPositionY + (lineItems[i - 1]["SizeY"] + lineItems[i]["SizeY"]) / 2;
        }

        lastPositionY = lineItems[i]["PositionY"];
        lastPosition.x = lineItems[i]["PositionX"];
        lastPosition.y = lineItems[i]["PositionY"];

        setOffsetVertical(lastPosition, lineItems[i]);

        // Prepare next lights to be moved according to the corner type (only left|right possible)
        for (let corner in corners) {
            if (corner === i.toString()) {
                // lastPosition.y -=  (lineItems[i]["SizeY"] * 0.5 + lineItems[i]["SizeX"] * 0.5);
                if (corners[corner] == "L") {
                    lastPosition.x -= uldCornerLength() / 2;
                } else {
                    // Right curve must be rotated for correct orientation
                    lineItems[i]["RotationZ"] = 270;
                    lastPosition.x += uldCornerLength() / 2;
                }
            }
        }
    }
    return lineItems;
};

export const lineDown = (lineItems, lastPosition, corners) => {
    let lastPositionY = lastPosition.y;
    for (let i = 0; i < lineItems.length; i++) {
        // Reset values from calculation and rotation 90° on z
        lineItems[i]["PositionY"] = lastPosition.y;
        lineItems[i]["PositionX"] = lastPosition.x;
        lineItems[i]["RotationZ"] = 0; // Zero rotation means the line goes vertical

        // Moving from segment to segment
        // If first element: do not apply the length of the previous light
        if (i === 0) {
            lineItems[i]["PositionY"] = lastPositionY - lineItems[i]["SizeY"] / 2;
        } else {
            // Additional segment's middle point is half the length of the current + last segment below the last segment's middle point
            lineItems[i]["PositionY"] = lastPositionY - (lineItems[i - 1]["SizeY"] + lineItems[i]["SizeY"]) / 2;
        }

        lastPositionY = lineItems[i]["PositionY"];
        lastPosition.x = lineItems[i]["PositionX"];
        lastPosition.y = lineItems[i]["PositionY"];

        setOffsetVertical(lastPosition, lineItems[i]);

        // Prepare next lights to be moved according to the corner type (only left|right possible)
        for (let corner in corners) {
            if (corner === i.toString()) {
                // lastPosition.y -=  (lineItems[i]["SizeY"] * 0.5 - lineItems[i]["SizeX"] * 0.5);
                if (corners[corner] == "L") {
                    lastPosition.x += uldCornerLength() / 2;
                } else {
                    // Right curve must be rotated for correct orientation
                    lineItems[i]["RotationZ"] = 90;
                    lastPosition.x -= uldCornerLength() / 2;
                }
                lineItems[i]["LightEmittingObjects"][0]["Shape2DID"] = 1;
                lineItems[i]["LightEmittingObjects"][0]["SizeX"] = lineItems[i]["SizeX"];
                lineItems[i]["LightEmittingObjects"][0]["SizeY"] = lineItems[i]["SizeY"];
                lineItems[i]["LightEmittingObjects"][0]["SizeZ"] = lineItems[i]["SizeZ"];
                lineItems[i]["LightEmittingObjects"][0]["PositionY"] = lineItems[i]["PositionY"];
                lineItems[i]["LightEmittingObjects"][0]["PositionX"] = lineItems[i]["PositionX"];
                lineItems[i]["LightEmittingObjects"][0]["PositionZ"] = lineItems[i]["PositionZ"];
            }
        }
    }
    return lineItems;
};

export const lineLeft = (lineItems, lastPosition, corners) => {
    let lastPositionX = lastPosition.x; // Start from last x-position
    for (let i = 0; i < lineItems.length; i++) {
        // Reset values from calculation and rotation 90° on z
        lineItems[i]["PositionY"] = lastPosition.y;
        lineItems[i]["RotationZ"] = 90;

        if (i === 0) {
            lineItems[i]["PositionX"] = lastPositionX - lineItems[i]["SizeY"] / 2;
        } else {
            // Additional segment's middle point is half the length of the current + last segment below the last segment's middle point
            lineItems[i]["PositionX"] = lastPositionX - (lineItems[i - 1]["SizeY"] + lineItems[i]["SizeY"]) / 2;
        }
        lastPositionX = lineItems[i]["PositionX"];
        lastPosition.x = lineItems[i]["PositionX"];
        lastPosition.y = lineItems[i]["PositionY"];
        setOffsetVertical(lastPosition, lineItems[i]);

        // Prepare next lights to be moved according to the corner type (only left|right possible)
        for (let corner in corners) {
            if (corner === i.toString()) {
                // lastPosition.x -=  (lineItems[i]["SizeY"] * 0.5 + lineItems[i]["SizeX"] * 0.5);
                if (corners[corner] == "L") {
                    lastPosition.y -= uldCornerLength() / 2;
                } else {
                    // Right curve must be rotated for correct orientation
                    lineItems[i]["RotationZ"] = 0;
                    lastPosition.y += uldCornerLength() / 2;
                }
            }
        }
    }
    return lineItems;
};

/**
 * @param installation
 * @param rulesetName
 * @param {*} color
 * @param {{[p: string]: T}} lineItems
 * @param {string} combinationProducts
 * @param {string} diffusor
 * @param power
 * @param configurationID
 * @param {string} form
 */
export const getDialuxData = (installation, rulesetName, color, lineItems, combinationProducts, diffusor, power, configurationID, form) => {
    let mountingTypeID = installation === "recessed" ? 8 : installation === "ceiling" ? 1 : 16;
    let pictureFileName = rulesetName.toLowerCase().replace(" ", "_") + ".jpg";
    let timeStamp = new Date().toISOString().slice(0, 10);
    let colorTemperature = color + "K";
    let combiProducts = JSON.parse(combinationProducts);

    let images = [];
    let m3ds = [];

    let data = {
        Product: {
            Manufacturer: "REGENT",
            ArticleID: configurationID,
            TimeStamp: timeStamp,
            PictureFileName: pictureFileName,
            ArticleName: {
                2057: rulesetName,
            },
            ArticleDescription: {
                2057: "",
            },
            ArticleNumber: {
                2057: "12345",
            },
            MountingTypes: [
                {
                    MountingTypeID: mountingTypeID,
                    DefaultDistance: 0,
                    MountingHeight: 0,
                },
            ],
            DataItems: [],
        },
    };

    var calculationResults = [];
    if (form === "S") {
        calculationResults = dialuxS(data.Product.DataItems, images, lineItems, combiProducts, colorTemperature, power, diffusor);
        data.Product.DataItems = calculationResults[0];
        images = calculationResults[1];
        m3ds = calculationResults[2];
    } else if (form === "O") {
        calculationResults = dialuxO(data.Product.DataItems, images, lineItems, combiProducts, colorTemperature, power, diffusor);
        data.Product.DataItems = calculationResults[0];
        images = calculationResults[1];
        m3ds = calculationResults[2];
    } else if (form === "U") {
        calculationResults = dialuxU(data.Product.DataItems, images, lineItems, combiProducts, colorTemperature, power, diffusor);
        data.Product.DataItems = calculationResults[0];
        images = calculationResults[1];
        m3ds = calculationResults[2];
    } else if (form === "Z") {
        calculationResults = dialuxZ(data.Product.DataItems, images, lineItems, combiProducts, colorTemperature, power, diffusor);
        data.Product.DataItems = calculationResults[0];
        images = calculationResults[1];
        m3ds = calculationResults[2];
    } else if (form === "I") {
        calculationResults = dialuxI(data.Product.DataItems, images, lineItems, combiProducts, colorTemperature, power, diffusor);
        data.Product.DataItems = calculationResults[0];
        images = calculationResults[1];
        m3ds = calculationResults[2];
    } else if (form === "L") {
        calculationResults = dialuxL(data.Product.DataItems, images, lineItems, combiProducts, colorTemperature, power, diffusor);
        data.Product.DataItems = calculationResults[0];
        images = calculationResults[1];
        m3ds = calculationResults[2];
    }

    // Remove duplicates in image array
    images = [...new Set(images)];
    m3ds = [...new Set(m3ds)];

    let returnValue = {};
    returnValue.configID = configurationID;
    returnValue.dialuxData = data;
    returnValue.images = images;
    returnValue.m3ds = m3ds;

    return returnValue;
};

// Create the current item
/**
 *
 * @param {*} itemSizeX
 * @param {*} itemSizeY
 * @param {*} itemSizeZ
 * @param {*} colorTemperature
 * @param {*} itemLumen
 * @param {*} itemWatt
 * @param {*} itemLDT
 * @returns
 */
export const createDialuxItem = (itemSizeX, itemSizeY, itemSizeZ, colorTemperature, itemLumen, itemWatt, itemLDT, itemM3D, cornerStatus, LEO) => {
    if (itemM3D === null) {
        return {
            SizeX: itemSizeX,
            SizeY: cornerStatus === true ? uldCornerLength() : itemSizeY,
            SizeZ: itemSizeZ,
            PositionX: 0,
            PositionY: 0,
            PositionZ: 0,
            RotationX: 0,
            RotationY: 0,
            RotationZ: 0,
            Shape3DID: 1,
            Corner: cornerStatus,
            LightEmittingObjects: [
                {
                    Name: LEO,
                    LumDistTypeID: 1,
                    PhotometryFileName: itemLDT,
                    Equipments: [
                        {
                            ColorRenderingIndex: 84,
                            ColorTemperature: colorTemperature,
                            SpectrumFileName: "example.spec",
                            TotalFlux: itemLumen,
                            TotalLoad: itemWatt,
                            LampName: {
                                2057: "LED " + itemWatt + "W " + colorTemperature,
                            },
                            NumberOfLamps: 1,
                        },
                    ],
                },
            ],
        };
    } else {
        return {
            SizeX: itemSizeX,
            SizeY: cornerStatus === true ? uldCornerLength() : itemSizeY,
            SizeZ: itemSizeZ,
            PositionX: 0,
            PositionY: 0,
            PositionZ: 0,
            RotationX: 0,
            RotationY: 0,
            RotationZ: 0,
            Shape3DID: 1,
            Corner: cornerStatus,
            M3DFileName: itemM3D,
            LightEmittingObjects: [
                {
                    Name: LEO,
                    LumDistTypeID: 1,
                    PhotometryFileName: itemLDT,
                    Equipments: [
                        {
                            ColorRenderingIndex: 84,
                            ColorTemperature: colorTemperature,
                            SpectrumFileName: "example.spec",
                            TotalFlux: itemLumen,
                            TotalLoad: itemWatt,
                            LampName: {
                                2057: "LED " + itemWatt + "W " + colorTemperature,
                            },
                            NumberOfLamps: 1,
                        },
                    ],
                },
            ],
        };
    }
};

export const getLumen = (combinationProduct, item) => {
    if (combinationProduct.length) {
        return combinationProduct[0].lm;
    } else {
        return item.lm;
    }
};
export const getWatt = (combinationProduct, item) => {
    if (combinationProduct.length) {
        return combinationProduct[0].system_watt;
    } else {
        return item.system_watt;
    }
};
export const getLDT = (combinationProduct, item) => {
    if (item.product_line === "Flow") {
        return item.ldt;
    } else {
        if (combinationProduct.length) {
            return combinationProduct[0].ldt;
        } else {
            return item.ldt;
        }
    }
};
export const getM3D = (combinationProduct, item) => {
    if (combinationProduct.length) {
        return combinationProduct[0].m3d;
    } else {
        return item.m3d;
    }
};
/**
 * Sets the min-max offset of light length(SizeY) for height y
 * @param {*} lastPosition
 * @param {*} lineItem
 */
export const setOffsetVertical = (lastPosition, lineItem) => {
    // Get pure offset from zero point to move the installation always in the pure positive realm
    // Last middle point of light --> check all four corners of the light
    if (lastPosition.maxX < lineItem["PositionX"] + lineItem.SizeX * 0.5) {
        lastPosition.maxX = lineItem["PositionX"] + lineItem.SizeX * 0.5;
    }
    if (lastPosition.minX > lineItem["PositionX"] - lineItem.SizeX * 0.5) {
        lastPosition.minX = lineItem["PositionX"] - lineItem.SizeX * 0.5;
    }
    if (lastPosition.minY > lineItem["PositionY"] + lineItem.SizeY * 0.5) {
        lastPosition.minY = lineItem["PositionY"] + lineItem.SizeY * 0.5;
    }
    if (lastPosition.maxY < lineItem["PositionY"] - lineItem.SizeY * 0.5) {
        lastPosition.maxY = lineItem["PositionY"] - lineItem.SizeY * 0.5;
    }
};
/**
 * Sets the min-max offset uses light length(SizeY) for width x
 * @param {*} lastPosition
 * @param {*} lineItem
 */
export const setOffsetHorizontal = (lastPosition, lineItem) => {
    // Get pure offset from zero point to move the installation always in the pure positive realm
    // Last middle point of light --> check all four corners of the light
    if (lastPosition.maxX < lineItem["PositionX"] + lineItem.SizeY * 0.5) {
        lastPosition.maxX = lineItem["PositionX"] + lineItem.SizeY * 0.5;
    }
    if (lastPosition.minX > lineItem["PositionX"] - lineItem.SizeY * 0.5) {
        lastPosition.minX = lineItem["PositionX"] - lineItem.SizeY * 0.5;
    }
    if (lastPosition.minY > lineItem["PositionY"] + lineItem.SizeX * 0.5) {
        lastPosition.minY = lineItem["PositionY"] + lineItem.SizeX * 0.5;
    }
    if (lastPosition.maxY < lineItem["PositionY"] - lineItem.SizeX * 0.5) {
        lastPosition.maxY = lineItem["PositionY"] - lineItem.SizeX * 0.5;
    }
};
export const changeProcessedCorners = (corners, segment) => {
    let newCorners = {};
    for (let key in corners) {
        newCorners[parseInt(key) - segment.length] = corners[key];
    }
    return newCorners;
};
